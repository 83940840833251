<template>
  <div>
    <b-modal
      :title="$t('modal.feedback.title', {
        visualName: market ? market.visualName : $t('title.home.company', { appName: appName })
      })"
      :id="modalId"
      :ok-title="$t('modal.feedback.ok_button')"
      :cancel-title="$t('modal.general.cancel_button')"
      @show="resetForm"
      @ok="verifyForm">
      <b-form @submit.prevent="verifyForm">

        <!--NAME-->
        <b-form-group
          id="name-group"
          :label="$t('form.feedback.name')"
          :invalid-feedback="invalidName"
          :state="stateName"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="feedbackForm.name"
            :state="stateNameInput"
            :placeholder="$t('form.feedback.name')"
          ></b-form-input>
        </b-form-group>

        <!--PHONE-->
        <b-form-group
          id="phone-group"
          :label="$t('form.feedback.phone')"
          label-for="phone"
          :state="statePhone"
          :invalid-feedback="invalidPhone"
        >
          <vue-phone-number-input
            id="phone"
            @update="updatePhone"
            v-model="feedbackForm.phone"
            :error="$v.feedbackForm.phone.$error || (phoneNumberData && !isPhoneValid)"
            :only-countries="phoneAvailableCountries"
            :translations="phoneTextTranslation"
            :default-country-code="phoneDefaultCountryCode"/>
        </b-form-group>

        <!--EMAIL-->
        <b-form-group
          id="email-group"
          :label="$t('form.feedback.email')"
          :invalid-feedback="invalidEmail"
          :state="stateEmail"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="feedbackForm.email"
            :state="stateEmailInput"
            :placeholder="$t('form.feedback.email')"
          ></b-form-input>
        </b-form-group>

        <!--NOTES-->
        <b-form-group
          id="notes-group"
          :label="$t('form.feedback.notes')"
          :invalid-feedback="invalidNotes"
          :state="stateNotes"
          label-for="notes"
        >
          <b-form-textarea
            id="notes"
            v-model="feedbackForm.notes"
            :state="stateNotesInput"
            :placeholder="$t('form.feedback.notes')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <vue-recaptcha
          v-if="useRecaptcha"
          ref="recaptcha"
          @expired="onCaptchaExpired"
          @verify="onCaptchaVerified"
          @error="onRecaptchaError"
          :sitekey="recaptchaSiteKey"
          size="invisible" />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {
    APP_NAME,
    COUNTRIES_ARRAY_ONLY, PHONE_DEFAULT_COUNTRY_CODE, PHONE_NUMBER_INPUT_TRANSLATIONS,
    RECAPTCHA_SITE_KEY, USE_RECAPTCHA
  } from '@base/configs'
  import { email, required } from 'vuelidate/lib/validators'
  import { alphaSpaces } from '@base/customValidators'

  export const defaultFeedbackForm = {
    name: '',
    email: '',
    phone: '',
    notes: '',
  }

  export default {
    name: 'FeedbackModal',

    components: { VueRecaptcha },

    data () {
      return {
        phoneAvailableCountries: COUNTRIES_ARRAY_ONLY,
        phoneNumberData: null,
        feedbackForm: { ...defaultFeedbackForm },
        useRecaptcha: USE_RECAPTCHA,
        recaptchaToken: null,
        recaptchaSiteKey: RECAPTCHA_SITE_KEY,
        recaptchaError: false,
        appName: APP_NAME
      }
    },

    props: {
      modalId: {
        type: String,
        required: true
      },
      market: {
        type: Object,
        required: false,
      }
    },

    validations: {
      feedbackForm: {
        phone: {
          required
        },
        name: {
          required,
          alphaSpaces
        },
        email: {
          email,
          required
        },
        notes: {
          required
        },
      }
    },

    computed: {

      payloads () {
        return {
          name: this.feedbackForm.name || undefined,
          email: this.feedbackForm.email,
          notes: this.feedbackForm.notes,
          phone: this.phoneNumberData ? this.phoneNumberData.formattedNumber : undefined,
          grecaptchaToken: this.useRecaptcha ? this.recaptchaToken: undefined,
        }
      },

      phoneDefaultCountryCode () {
        return this.market.supportCountry ? this.market.supportCountry.code : PHONE_DEFAULT_COUNTRY_CODE
      },

      phoneTextTranslation () {
        return PHONE_NUMBER_INPUT_TRANSLATIONS[this.$i18n.locale.toUpperCase()]
      },


      // VALIDATIONS
      isPhoneValid () {
        return this.phoneNumberData && this.feedbackForm.phone && this.phoneNumberData.isValid
      },
      stateNameInput () {
        return this.$v.feedbackForm.name.$error ? false : null
      },
      stateName () {
        return (!this.$v.feedbackForm.name.$error)
      },
      invalidName () {
        if (this.$v.feedbackForm.name.$error) {
          if (!this.$v.feedbackForm.name.required)
            return this.$t('validation.required', { field: this.$t('form.feedback.name') })
          else if (!this.$v.feedbackForm.name.alphaSpaces)
            return this.$t('validation.alpha_spaces', { field: this.$t('form.feedback.name') })
        }
        return null
      },
      stateNotesInput () {
        return this.$v.feedbackForm.notes.$error ? false : null
      },
      stateNotes () {
        return (!this.$v.feedbackForm.notes.$error)
      },
      invalidNotes () {
        if (this.$v.feedbackForm.notes.$error) {
          if (!this.$v.feedbackForm.notes.required)
            return this.$t('validation.required', { field: this.$t('form.feedback.notes') })
        }
        return null
      },
      stateEmailInput () {
        return this.$v.feedbackForm.email.$error ? false : null
      },
      stateEmail () {
        return (!this.$v.feedbackForm.email.$error)
      },
      invalidEmail () {
        if (this.$v.feedbackForm.email.$error) {
          if (!this.$v.feedbackForm.email.required)
            return this.$t('validation.required', { field: this.$t('form.feedback.email') })
          else if (!this.$v.feedbackForm.email.email)
            return this.$t('validation.email')
        }
        return null
      },
      statePhone () {
        return (!this.$v.feedbackForm.phone.$error)
      },
      invalidPhone () {
        if (this.$v.feedbackForm.phone.$error) {
          if (!this.$v.feedbackForm.phone.required)
            return this.$t('validation.required', { field: this.$t('form.feedback.phone') })
        }
        return null
      },
    },

    methods: {

      onRecaptchaError () {
        this.recaptchaError = true
      },

      onCaptchaExpired () {
        if (this.useRecaptcha)
          this.$refs.recaptcha.reset()
      },

      updatePhone (phoneObj) {
        this.phoneNumberData = phoneObj
      },

      resetForm () {
        this.$v.$reset()
        this.feedbackForm = { ...defaultFeedbackForm }
        this.phoneNumberData = null
      },

      verifyForm (bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$v.$touch()
        if (this.$v.$invalid || !this.isPhoneValid) {
          return
        }
        if (this.useRecaptcha) {
          this.$refs.recaptcha.execute()
        }
        else
          this.onCaptchaVerified()
      },

      onCaptchaVerified (recaptchaToken) {
        if (this.useRecaptcha) {
          this.recaptchaToken = recaptchaToken
          this.$refs.recaptcha.reset()
        }
        this.$emit('formSubmit', this.payloads)
      }
    }
  }
</script>

<style>

</style>
