import Vue from 'vue'
import Vuex from 'vuex'

import {
  adminChangePassword,
  updateAdmin,
  getAdmin,
  getAdminMarket,
  updateMarketSettings,
  marketLogo,
  createMarketImage,
  deleteMarketImage,
  getCustomerList,
  getAllCustomers,
  getCustomer,
  editCustomer,
  addCustomer,
  deleteCustomer,
  sendEmailConfirmation,
  resetCustomerPassword,
  verifyCustomer,
  getPaymentsInfo,
  getSavedPeriods,
  getShelfList,
  editShelf,
  addShelf,
  deleteShelf,
  getCampaignList,
  editCampaign,
  addCampaign,
  deleteCampaign,
  getShelfBookingList,
  editShelfBooking,
  addShelfBooking,
  deleteShelfBooking,
  getCustomerBookings,
  salesPeriods,
  bankExportTransactions,
  economicLayouts,
  marketMessages,
  bookingsReport,
  customerCampaignsReport,
  customerProductSheets,
  createCustomerProductSheet,
  updateCustomerProductSheet,
  deleteCustomerProductSheet,
  getCustomerProducts,
  createCustomerProduct,
  productCampaigns,
  getCustomerProductSaleLines,
} from '@admin/services'

import {
  baseActions,
  baseGetters,
  baseMutations,
  baseState,
  adminRequestInterceptor,
  cleanStorages,
  adminResponseFulfilledInterceptor,
  adminResponseRejectedInterceptor,
  getToken,
  setToken,
} from '@base/store'

import { actionTypes, mutationTypes } from '@base/store/store-types'
import { webConfig } from '@base/configs'
import { revokeToken } from '@base/services'
import i18n from '@base/i18n'

Vue.use(Vuex)

const initialState = {
  ...baseState,
  adminMarket: null,
  customer: null,
  customerList: [],
  allCustomers: [],
  customerArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  paymentsInfo: [],
  paymentsInfoArg: {
    filters: null
  },
  savedPeriods: [],
  shelfList: [],
  shelfArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  campaignList: [],
  campaignArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  shelfBookingList: [],
  shelfBookingArg: {
    filters: null
  },
  customerBookingList: [],
  customerBookingArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  selectedCustomerProductSheet: null,
  customerProductSaleLines: [],
  customerProductSheets: [],
  customerProductSheetsArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  customerProducts: [],
  customerProductsArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  saveType: null,
  bankExportTransactions: [],
  bankExportTransactionsArg: {
    filters: null
  },
  economicLayouts: [],
  marketMessages: [],
  marketMessagesArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  unseenMessagesNumber: 0,
  adminNavItems: [],
  bookingsReport: [],
  bookingsReportArg: {
    filters: null
  },
  customerCampaignsReport: [],
  customerCampaignsReportArg: {
    page: 1,
    pagesCount: 0,
    filters: null,
  }
}

const axiosInstances = [
  adminChangePassword,
  updateAdmin,
  getAdmin,
  getAdminMarket,
  updateMarketSettings,
  marketLogo,
  createMarketImage,
  deleteMarketImage,
  getCustomerList,
  getAllCustomers,
  getCustomer,
  editCustomer,
  addCustomer,
  deleteCustomer,
  sendEmailConfirmation,
  resetCustomerPassword,
  verifyCustomer,
  getPaymentsInfo,
  getSavedPeriods,
  getCampaignList,
  editCampaign,
  addCampaign,
  deleteCampaign,
  getShelfList,
  editShelf,
  addShelf,
  deleteShelf,
  getShelfBookingList,
  editShelfBooking,
  addShelfBooking,
  deleteShelfBooking,
  getCustomerBookings,
  salesPeriods,
  bankExportTransactions,
  economicLayouts,
  marketMessages,
  bookingsReport,
  customerCampaignsReport,
  customerProductSheets,
  createCustomerProductSheet,
  updateCustomerProductSheet,
  deleteCustomerProductSheet,
  getCustomerProducts,
  createCustomerProduct,
  productCampaigns,
  getCustomerProductSaleLines,
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(adminRequestInterceptor)
  instance.interceptors.response.use(
    adminResponseFulfilledInterceptor,
    adminResponseRejectedInterceptor
  )
}


document.initialState = initialState      // Needs to be in each section stores for base auth user behavior

export default new Vuex.Store({
  state: initialState,
  getters: {
    ...baseGetters,

    getCustomerPage: state => state.customerArg.page,
    getCustomerPagesCount: state => state.customerArg.pagesCount,

    getShelfPage: state => state.shelfArg.page,
    getShelfPagesCount: state => state.shelfArg.pagesCount,

    getCampaignPage: state => state.campaignArg.page,
    getCampaignPagesCount: state => state.campaignArg.pagesCount,

    getCustomerProductsPage: state => state.customerProductsArg.page,
    getCustomerProductsPagesCount: state => state.customerProductsArg.pagesCount,

    getCustomerBookingPage: state => state.customerBookingArg.page,
    getCustomerBookingPagesCount: state => state.customerBookingArg.pagesCount,

    getCustomerProductSheetPage: state => state.customerProductSheetsArg.page,
    getCustomerProductSheetPagesCount: state => state.customerProductSheetsArg.pagesCount,

    getMarketMessagesPage: state => state.marketMessagesArg.page,
    getMarketMessagesPagesCount: state => state.marketMessagesArg.pagesCount,

    getCustomerCampaignsReportPage: state => state.customerCampaignsReportArg.page,
    getCustomerCampaignsReportPagesCount: state => state.customerCampaignsReportArg.pagesCount,

  },
  mutations: {
    ...baseMutations,

    // eslint-disable-next-line no-unused-vars
    [mutationTypes.LOGOUT_ADMIN](state, url) {
      state = document.initialState
      cleanStorages('AdminToken')
      document.location = url ? url : webConfig.baseURL
    },
    [mutationTypes.SET_ADMIN_MARKET](state, market) {
      state.adminMarket = market
    },
    [mutationTypes.SET_MARKET_SETTINGS](state, market) {
      state.adminMarket = market
    },
    [mutationTypes.UPDATE_MARKET_LOGO](state, data) {
      state.adminMarket = {
        ...state.adminMarket,
        logo: data.logo
      }
    },
    [mutationTypes.DELETE_MARKET_LOGO](state) {
      state.adminMarket = {
        ...state.adminMarket,
        logo: null
      }
    },
    [mutationTypes.APPEND_MARKET_IMAGE](state, newImage) {
      state.adminMarket.marketImages.push(newImage)
    },
    [mutationTypes.DELETE_MARKET_IMAGE](state, imageId) {
      const oldImage = state.adminMarket.marketImages.findIndex(
        obj => obj.id === imageId
      )
      state.adminMarket.marketImages.splice(oldImage, 1)
    },

    [mutationTypes.SET_CUSTOMER_LIST](state, customers) {
      state.customerList = customers
    },
    [mutationTypes.SET_ALL_CUSTOMERS](state, customers) {
      state.allCustomers = customers
    },
    [mutationTypes.SET_CUSTOMER](state, customer) {
      state.customer = customer
    },
    [mutationTypes.SET_CUSTOMER_PAGE] (state, page) {
      state.customerArg.page = page
    },
    [mutationTypes.SET_CUSTOMER_FILTERS] (state, filters) {
      state.customerArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_PAGES_COUNT] (state, count) {
      state.customerArg.pagesCount = count
    },
    [mutationTypes.APPEND_CUSTOMER](state, customer) {
      state.customerList.unshift(customer)
    },
    [mutationTypes.DELETE_CUSTOMER](state, userNumber) {
      const oldCustomerIndex = state.customerList.findIndex(customer => customer.userNumber === userNumber)
      if (oldCustomerIndex >= 0)
        state.customerList.splice(oldCustomerIndex, 1)
    },
    [mutationTypes.UPDATE_CUSTOMER](state, { oldUserNumber, customer }) {
      const oldCustomerObj = state.customerList.find(obj => obj.userNumber === oldUserNumber)
      if (oldCustomerObj)
        Object.assign(oldCustomerObj, customer)
    },
    [mutationTypes.VERIFY_CUSTOMER] (state, { userNumber, verified }) {
      const customerObj = state.customerList.find(obj => obj.userNumber === userNumber)
      customerObj.verified = verified
    },
    [mutationTypes.SET_PAYMENTS_INFO](state, paymentsInfo) {
      state.paymentsInfo = paymentsInfo
    },
    [mutationTypes.SET_SAVED_PERIODS](state, savedPeriods) {
      state.savedPeriods = savedPeriods
    },
    [mutationTypes.DELETE_SAVED_PERIOD](state, id) {
      const oldSavedPeriodIndex = state.savedPeriods.findIndex(shelf => shelf.id === id)
      if (oldSavedPeriodIndex >= 0)
        state.savedPeriods.splice(oldSavedPeriodIndex, 1)
    },
    [mutationTypes.SET_ECONOMIC_EXPORT_REQUESTED](state, id) {
      const salesPeriodObj = state.savedPeriods.find(obj => obj.id === id)
      salesPeriodObj.economicExportRequested = true
    },
    [mutationTypes.SET_SALE_LINES_FILTERS] (state, filters) {
      state.paymentsInfoArg.filters = filters
    },
    [mutationTypes.SET_SHELF_LIST](state, shelves) {
      state.shelfList = shelves
    },
    [mutationTypes.SET_SHELF_FILTERS] (state, filters) {
      state.shelfArg.filters = filters
    },
    [mutationTypes.SET_SHELF_PAGE] (state, page) {
      state.shelfArg.page = page
    },
    [mutationTypes.SET_SHELF_PAGES_COUNT] (state, count) {
      state.shelfArg.pagesCount = count
    },
    [mutationTypes.APPEND_SHELF](state, shelf) {
      state.shelfList.unshift(shelf)
    },
    [mutationTypes.DELETE_SHELF](state, id) {
      const oldShelfIndex = state.shelfList.findIndex(shelf => shelf.id === id)
      if (oldShelfIndex >= 0)
        state.shelfList.splice(oldShelfIndex, 1)
    },
    [mutationTypes.UPDATE_SHELF](state, { id, shelf }) {
      const oldShelfObj = state.shelfList.find(obj => obj.id === id)
      Object.assign(oldShelfObj, shelf)
    },
    [mutationTypes.SET_CAMPAIGN_LIST](state, campaigns) {
      state.campaignList = campaigns
    },
    [mutationTypes.SET_CAMPAIGN_FILTERS] (state, filters) {
      state.campaignArg.filters = filters
    },
    [mutationTypes.SET_CAMPAIGN_PAGE] (state, page) {
      state.campaignArg.page = page
    },
    [mutationTypes.SET_CAMPAIGN_PAGES_COUNT] (state, count) {
      state.campaignArg.pagesCount = count
    },
    [mutationTypes.APPEND_CAMPAIGN](state, campaign) {
      state.campaignList.unshift(campaign)
    },
    [mutationTypes.DELETE_CAMPAIGN](state, id) {
      const oldCampaignIndex = state.campaignList.findIndex(shelf => shelf.id === id)
      if (oldCampaignIndex >= 0)
        state.campaignList.splice(oldCampaignIndex, 1)
    },
    [mutationTypes.UPDATE_CAMPAIGN](state, { id, campaign }) {
      const oldCampaignObj = state.campaignList.find(obj => obj.id === id)
      Object.assign(oldCampaignObj, campaign)
    },
    [mutationTypes.SET_SHELF_BOOKING_LIST](state, bookings) {
      state.shelfBookingList = bookings
    },
    [mutationTypes.SET_SHELF_BOOKING_FILTERS] (state, filters) {
      state.shelfBookingArg.filters = filters
    },
    [mutationTypes.APPEND_SHELF_BOOKING](state, shelfBooking) {
      state.shelfBookingList.unshift(shelfBooking)
    },
    [mutationTypes.DELETE_SHELF_BOOKING](state, id) {
      const oldShelfBookingIndex = state.shelfBookingList.findIndex(shelfBooking => shelfBooking.id === id)
      if (oldShelfBookingIndex >= 0)
        state.shelfBookingList.splice(oldShelfBookingIndex, 1)
    },
    [mutationTypes.UPDATE_SHELF_BOOKING](state, { id, shelfBooking }) {
      const oldShelfBookingObj = state.shelfBookingList.find(obj => obj.id === id)
      Object.assign(oldShelfBookingObj, shelfBooking)
    },
    [mutationTypes.SET_CUSTOMER_BOOKINGS](state, bookings) {
      state.customerBookingList = bookings
    },
    [mutationTypes.SET_CUSTOMER_BOOKING_PAGE] (state, page) {
      state.customerBookingArg.page = page
    },
    [mutationTypes.SET_CUSTOMER_BOOKING_FILTERS] (state, filters) {
      state.customerBookingArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_BOOKING_PAGES_COUNT] (state, count) {
      state.customerBookingArg.pagesCount = count
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SHEETS](state, bookings) {
      state.customerProductSheets = bookings
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SHEETS_PAGE] (state, page) {
      state.customerProductSheetsArg.page = page
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SHEETS_FILTERS] (state, filters) {
      state.customerProductSheetsArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SHEETS_PAGES_COUNT] (state, count) {
      state.customerProductSheetsArg.pagesCount = count
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SHEET] (state, sheet) {
      state.selectedCustomerProductSheet = sheet
      if (sheet) {
        const token = getToken('AdminToken')
        if (token.zettleIntegration) {
          state.selectedCustomerProductSheet.products = sheet.zettleProducts
        } else {
          state.selectedCustomerProductSheet.products = sheet.flexposProducts
        }
      }
    },
    [mutationTypes.UPDATE_CUSTOMER_PRODUCT_SHEET](state, { id, sheet }) {
      const oldProductSheetObj = state.customerProductSheets.find(obj => obj.id === id)
      Object.assign(oldProductSheetObj, sheet)
    },
    [mutationTypes.APPEND_CUSTOMER_PRODUCT_SHEET](state, sheet) {
      state.customerProductSheets.unshift(sheet)
    },
    [mutationTypes.DELETE_CUSTOMER_PRODUCT_SHEET](state, id) {
      const oldProductSheetIndex = state.customerProductSheets.findIndex(sheet => sheet.id === id)
      if (oldProductSheetIndex >= 0)
        state.customerProductSheets.splice(oldProductSheetIndex, 1)
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_SALE_LINE_LIST] (state, lines) {
      state.customerProductSaleLines = lines
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_LIST] (state, products) {
      state.customerProducts = products
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_FILTERS] (state, filters) {
      state.customerProductsArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_PAGE] (state, page) {
      state.customerProductsArg.page = page
    },
    [mutationTypes.SET_CUSTOMER_PRODUCT_PAGES_COUNT] (state, count) {
      state.customerProductsArg.pagesCount = count
    },
    [mutationTypes.SET_SAVE_TYPE](state, saveType) {
      state.saveType = saveType
    },
    [mutationTypes.SET_BANK_EXPORT_TRANSACTIONS](state, transactions) {
      state.bankExportTransactions = transactions
    },
    [mutationTypes.SET_BANK_EXPORT_TRANSACTIONS_FILTERS] (state, filters) {
      state.bankExportTransactionsArg.filters = filters
    },
    [mutationTypes.UPDATE_BANK_EXPORT_TRANSACTION](state, transaction) {
      const oldBankExportTransactionObj = state.bankExportTransactions.find(obj => obj.id === transaction.id)
      if (oldBankExportTransactionObj)
        Object.assign(oldBankExportTransactionObj, transaction)
    },
    [mutationTypes.DELETE_BANK_EXPORT_TRANSACTION](state, id) {
      const oldObjectIndex = state.bankExportTransactions.findIndex(
        obj => obj.id === id
      )
      if (oldObjectIndex >= 0)
        state.bankExportTransactions.splice(oldObjectIndex, 1)
    },
    [mutationTypes.SET_ECONOMIC_LAYOUTS](state, layouts) {
      state.economicLayouts = layouts
    },
    [mutationTypes.SET_MARKET_MESSAGES](state, messages) {
      state.marketMessages = messages
    },
    [mutationTypes.SET_MARKET_MESSAGES_FILTERS] (state, filters) {
      state.marketMessagesArg.filters = filters
    },
    [mutationTypes.SET_MARKET_MESSAGES_PAGE] (state, page) {
      state.marketMessagesArg.page = page
    },
    [mutationTypes.SET_MARKET_MESSAGES_PAGES_COUNT] (state, count) {
      state.marketMessagesArg.pagesCount = count
    },
    [mutationTypes.SET_UNSEEN_MESSAGES_NUMBER] (state, number) {
      state.unseenMessagesNumber = number
    },
    [mutationTypes.DELETE_MARKET_MESSAGE](state, id) {
      const oldObjectIndex = state.marketMessages.findIndex(
        obj => obj.id === id
      )
      if (oldObjectIndex >= 0)
        state.marketMessages.splice(oldObjectIndex, 1)
    },
    [mutationTypes.UPDATE_MARKET_MESSAGES](state, idList) {
      state.marketMessages = state.marketMessages.filter(obj => !idList.includes(obj.id))
    },
    [mutationTypes.UPDATE_MARKET_MESSAGE](state, { id, marketMessage }) {
      const oldObj = state.marketMessages.find(obj => obj.id === id)
      if (oldObj)
        Object.assign(oldObj, marketMessage)
    },
    [mutationTypes.DELETE_MARKET_MESSAGES](state, idList) {
      state.marketMessages = state.marketMessages.filter(obj => !idList.includes(obj.id))
    },
    [mutationTypes.SET_ADMIN_NAV_ITEMS](state) {
      state.adminNavItems = [
        {
          href: { name: 'customers' },
          text: i18n.t('navigation.admin.customers')
        },
        {
          href: { name: 'payments' },
          text: i18n.t('navigation.admin.payments')
        },
        {
          href: { name: 'booking' },
          text: i18n.t('navigation.admin.booking')
        },
        {
          href: { name: 'market-settings' },
          text: i18n.t('navigation.admin.market_settings')
        },
        {
          href: { name: 'reports' },
          text: i18n.t('navigation.admin.reports')
        },
        {
          href: { name: 'messages' },
          text: i18n.t('navigation.admin.messages'),
          badge: true,
          badgeVariant: 'danger',
          badgeText: state.unseenMessagesNumber
        },
        {
          href: '#',
          text: i18n.t('navigation.general.logout')
        }
      ]
      const token = getToken('AdminToken')
      if (token.zettleIntegration) {
        state.adminNavItems.splice(1, 0, {
          href: { name: 'campaigns' },
          text: i18n.t('navigation.admin.campaigns')
        })
      }
    },
    [mutationTypes.SET_BOOKINGS_REPORT](state, bookings) {
      state.bookingsReport = bookings
    },
    [mutationTypes.SET_BOOKINGS_REPORT_FILTERS] (state, filters) {
      state.bookingsReportArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_CAMPAIGN_REPORT](state, messages) {
      state.customerCampaignsReport = messages
    },
    [mutationTypes.SET_CUSTOMER_CAMPAIGN_REPORT_FILTERS](state, filters) {
      state.customerCampaignsReportArg.filters = filters
    },
    [mutationTypes.SET_CUSTOMER_CAMPAIGN_REPORT_PAGE] (state, page) {
      state.customerCampaignsReportArg.page = page
    },
    [mutationTypes.SET_CUSTOMER_CAMPAIGN_REPORT_PAGES_COUNT] (state, count) {
      state.customerCampaignsReportArg.pagesCount = count
    },
  },
  actions: {
    ...baseActions,

    [actionTypes.GET_ADMIN_MARKET]({ state, commit }) {
      return new Promise((resolve, reject) => {
        getAdminMarket({
          url: `${state.marketId}/`
        })
          .then(response => {
            commit(mutationTypes.SET_ADMIN_MARKET, response.data)
            commit(mutationTypes.SET_UNSEEN_MESSAGES_NUMBER, response.data.unseenMessagesNumber)
            commit(mutationTypes.SET_ADMIN_NAV_ITEMS)
            const token = getToken('AdminToken')
            if (token) {
              token.individualizedItemsAccess = response.data.individualizedItemsAccess
              setToken(token)
            }
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_ADMIN_DATA]({ commit }) {
      return new Promise((resolve, reject) => {
        getAdmin()
          .then((response) => {
            commit(mutationTypes.SET_USER_DATA, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.CHANGE_ADMIN_DATA] ({commit}, payloads) {
      return new Promise((resolve, reject) => {
        updateAdmin({
          data: payloads
        })
          .then((response) => {
            const token = getToken('AdminToken')
            if (token) {
              token.username = response.data.username
              setToken(token)
              commit(mutationTypes.SET_USER_DATA, response.data)
            } else {
              commit(mutationTypes.LOGOUT_ADMIN)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.LOGOUT_ADMIN]({ commit }, url) {
      return new Promise((resolve, reject) => {
        const token = getToken('AdminToken')
        if (!token || !token.accessToken) {
          commit(mutationTypes.LOGOUT_ADMIN)
        }
        revokeToken({
          data: {'token': token.accessToken}
        })
          .then(response => {
            commit(mutationTypes.LOGOUT_ADMIN, url)
            resolve(response)
          })
          .catch(error => {
            commit(mutationTypes.LOGOUT_ADMIN)
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_SETTINGS]({ state, commit, dispatch }, { payloads, method = 'put' }) {
      return new Promise((resolve, reject) => {
        updateMarketSettings({
          method: method,
          url: `${state.marketId}/`,
          data: payloads
        })
          .then(response => {
            if (payloads.marketId) {
              const token = getToken('AdminToken')
              if (token) {
                if (token.marketId) {
                  token.marketId = response.data.marketId
                  token.flexposApiValid = response.data.flexposApiValid
                  token.zettleIntegration = response.data.zettleIntegration
                  token.zettleApiValid = response.data.zettleApiValid
                  token.economicApiValid = response.data.economicApiValid
                  token.individualizedItemsAccess = response.data.individualizedItemsAccess
                  setToken(token)
                  commit(mutationTypes.SET_MARKET_HEADER, token.marketId ? token.marketId : '')
                } else {
                  dispatch(actionTypes.LOGOUT_ADMIN)
                  reject({data: ['No marketId in token']})
                }
              } else {
                commit(mutationTypes.LOGOUT_ADMIN)
                reject({data: ['No token']})
              }
            }
            commit(mutationTypes.SET_MARKET_SETTINGS, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_LOGO]({ state, commit }, formDataLogoFile) {
      return new Promise((resolve, reject) => {
        marketLogo({
          url: `${state.marketId}/`,
          data: formDataLogoFile,
          method: 'put'
        })
          .then(response => {
            commit(mutationTypes.UPDATE_MARKET_LOGO, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_LOGO]({ state, commit }) {
      return new Promise((resolve, reject) => {
        marketLogo({
          url: `${state.marketId}/`,
          method: 'delete'
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_LOGO)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.CREATE_MARKET_IMAGE]({ commit }, imageFile) {
      return new Promise((resolve, reject) => {
        createMarketImage({
          data: imageFile
        })
          .then(response => {
            commit(mutationTypes.APPEND_MARKET_IMAGE, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_IMAGE]({ commit }, imageId) {
      return new Promise((resolve, reject) => {
        deleteMarketImage({
          url: `${imageId}/`,
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_IMAGE, imageId)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CUSTOMER_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCustomerList({
          params: {
            page: state.customerArg.page,
            ...state.customerArg.filters
          }
        })
          .then(response => {
            if (response.data.results)
              commit(mutationTypes.SET_CUSTOMER_LIST, response.data.results)
            else
              commit(mutationTypes.SET_CUSTOMER_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_ALL_CUSTOMERS]({ commit }) {
      return new Promise((resolve, reject) => {
        getAllCustomers()
          .then(response => {
            commit(mutationTypes.SET_ALL_CUSTOMERS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_CUSTOMER]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        getCustomer({
          url: `${userNumber}/`
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.ADD_CUSTOMER]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        addCustomer({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_CUSTOMER, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_CUSTOMER]({ commit }, { payloads, userNumber, method = 'put' }) {
      return new Promise((resolve, reject) => {
        editCustomer({
          method: method,
          url: userNumber + '/',
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_CUSTOMER, { oldUserNumber: userNumber, customer: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_CUSTOMER]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        deleteCustomer({
          url: userNumber + '/'
        })
          .then(response => {
            commit(mutationTypes.DELETE_CUSTOMER, userNumber)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.SEND_EMAIL_CONFIRMATION]({ commit }, email) {
      return new Promise((resolve, reject) => {
        sendEmailConfirmation({
          url: `${email}/`
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.VERIFY_CUSTOMER]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        verifyCustomer({
          url: userNumber + '/',
        })
          .then(response => {
            commit(mutationTypes.VERIFY_CUSTOMER, { userNumber: userNumber, verified: response.data.verified })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.RESET_CUSTOMER_PASSWORD]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        resetCustomerPassword({
          url: userNumber + '/',
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_PAYMENTS_INFO]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getPaymentsInfo({
          params: state.paymentsInfoArg.filters
        })
          .then(response => {
            commit(mutationTypes.SET_PAYMENTS_INFO, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_SAVED_PERIODS]({ commit }) {
      return new Promise((resolve, reject) => {
        getSavedPeriods()
          .then(response => {
            commit(mutationTypes.SET_SAVED_PERIODS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.SAVE_PAYMENTS_DATES]({ state }, paymentsDates) {
      return new Promise((resolve, reject) => {
        salesPeriods({
          method: 'post',
          params: state.saveType,
          data: paymentsDates
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_SAVED_PERIOD]({ commit }, id) {
      return new Promise((resolve, reject) => {
        salesPeriods({
          method: 'delete',
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_SAVED_PERIOD, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EXPORT_SALES_PERIOD_TO_ECONOMIC]({ commit }, id) {
      return new Promise((resolve, reject) => {
        salesPeriods({
          method: 'post',
          url: `${id}/economic_export/`
        })
          .then(response => {
            commit(mutationTypes.SET_ECONOMIC_EXPORT_REQUESTED, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_SHELF_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getShelfList({
          params: {
            page: state.shelfArg.page,
            ...state.shelfArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_SHELF_LIST, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.ADD_SHELF]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        addShelf({
          data: payloads
        })
          .then(response => {
            // If admin created many shelves (array response) -
            // append all of them to current list
            // If only one shelf (object response) - append only one then
            if (Array.isArray(response.data)) {
              for (const shelf of response.data) {
                if (Object.keys(shelf).length)
                  commit(mutationTypes.APPEND_SHELF, shelf)
              }
            } else {
              if (Object.keys(response.data).length)
                commit(mutationTypes.APPEND_SHELF, response.data)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_SHELF]({ commit }, { payloads, id }) {
      return new Promise((resolve, reject) => {
        editShelf({
          url: `${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_SHELF, { id: id, shelf: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_SHELF]({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteShelf({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_SHELF, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CAMPAIGN_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCampaignList({
          params: {
            page: state.campaignArg.page,
            ...state.campaignArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_CAMPAIGN_LIST, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.ADD_CAMPAIGN]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        addCampaign({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_CAMPAIGN, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_CAMPAIGN]({ commit }, { payloads, id }) {
      return new Promise((resolve, reject) => {
        editCampaign({
          url: `${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_CAMPAIGN, { id: id, campaign: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_CAMPAIGN]({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteCampaign({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_CAMPAIGN, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_SHELF_BOOKING_LIST]({ commit, state }, filters) {
      commit(mutationTypes.SET_SHELF_BOOKING_FILTERS, filters)
      return new Promise((resolve, reject) => {
        getShelfBookingList({
          params: state.shelfBookingArg.filters
        })
          .then(response => {
            commit(mutationTypes.SET_SHELF_BOOKING_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.ADD_SHELF_BOOKING]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        addShelfBooking({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_SHELF_BOOKING, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_SHELF_BOOKING]({ commit }, { payloads, id }) {
      return new Promise((resolve, reject) => {
        editShelfBooking({
          url: `${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_SHELF_BOOKING, { id: id, shelfBooking: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_SHELF_BOOKING]({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteShelfBooking({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_SHELF_BOOKING, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CUSTOMER_BOOKINGS]({ commit, state }, userNumber) {
      return new Promise((resolve, reject) => {
        getCustomerBookings({
          url: `${userNumber}/`,
          params: {
            page: state.customerBookingArg.page,
            ...state.customerBookingArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_BOOKINGS, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CUSTOMER_PRODUCT_SHEETS]({ commit, state }, userNumber) {
      return new Promise((resolve, reject) => {
        customerProductSheets({
          url: `${userNumber}/`,
          params: {
            page: state.customerProductSheetsArg.page,
            ...state.customerProductSheetsArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_SHEETS, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_CUSTOMER_PRODUCT_SHEET]({ commit }, {userNumber, id}) {
      return new Promise((resolve, reject) => {
        customerProductSheets({
          url: `${userNumber}/${id}/`
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_SHEET, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.CREATE_CUSTOMER_PRODUCT_SHEET]({ commit }, {userNumber, payloads}) {
      return new Promise((resolve, reject) => {
        createCustomerProductSheet({
          url: `${userNumber}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_CUSTOMER_PRODUCT_SHEET, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.UPDATE_CUSTOMER_PRODUCT_SHEET]({ commit }, { userNumber, payloads, id }) {
      return new Promise((resolve, reject) => {
        updateCustomerProductSheet({
          url: `${userNumber}/${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_SHEET, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.COMPLETE_CUSTOMER_PRODUCT_SHEET]({ commit }, {userNumber, id}) {
      return new Promise((resolve, reject) => {
        customerProductSheets({
          method: 'patch',
          url: `${userNumber}/${id}/complete/`
        })
          .then(response => {
            commit(mutationTypes.UPDATE_CUSTOMER_PRODUCT_SHEET, { id: id, sheet: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_CUSTOMER_PRODUCT_SHEET]({ commit }, { userNumber, id }) {
      return new Promise((resolve, reject) => {
        deleteCustomerProductSheet({
          url: `${userNumber}/${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_CUSTOMER_PRODUCT_SHEET, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CUSTOMER_PRODUCT_SALE_LINE_LIST]({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCustomerProductSaleLines({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_SALE_LINE_LIST, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CUSTOMER_PRODUCT_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCustomerProducts({
          params: {
            page: state.customerProductsArg.page,
            ...state.customerProductsArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_LIST, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.CREATE_CUSTOMER_PRODUCTS]({ commit }, { userNumber, payloads }) {
      return new Promise((resolve, reject) => {
        createCustomerProduct({
          url: `${userNumber}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_PRODUCT_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.PRINT_CUSTOMER_PRODUCT_SHEET]({ commit }, {userNumber, id}) {
      return new Promise((resolve, reject) => {
        customerProductSheets({
          method: 'patch',
          url: `${userNumber}/${id}/print/`
        })
          .then(response => {
            commit(mutationTypes.UPDATE_CUSTOMER_PRODUCT_SHEET, { id: id, sheet: response.data })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // eslint-disable-next-line no-unused-vars
    [actionTypes.ADD_PRODUCTS_TO_CAMPAIGN]({ commit }, {userNumber, id}) {
      return new Promise((resolve, reject) => {
        productCampaigns({
          method: 'post',
          url: `${userNumber}/${id}/add_products/`
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.REMOVE_PRODUCTS_FROM_CAMPAIGN]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        productCampaigns({
          method: 'post',
          url: `${userNumber}/remove_products/`
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_BANK_EXPORT_TRANSACTIONS]({ state, commit }) {
      return new Promise((resolve, reject) => {
        bankExportTransactions({
          params: state.bankExportTransactionsArg.filters,
        })
          .then(response => {
            commit(mutationTypes.SET_BANK_EXPORT_TRANSACTIONS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_BANK_EXPORT_TRANSACTION]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        bankExportTransactions({
          method: 'patch',
          url: `${payloads.id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_BANK_EXPORT_TRANSACTION, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_BANK_EXPORT_TRANSACTION]({ commit }, id) {
      return new Promise((resolve, reject) => {
        bankExportTransactions({
          method: 'delete',
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_BANK_EXPORT_TRANSACTION, id)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_ECONOMIC_LAYOUTS]({ commit }) {
      return new Promise((resolve, reject) => {
        economicLayouts()
          .then(response => {
            commit(mutationTypes.SET_ECONOMIC_LAYOUTS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_MARKET_MESSAGES]({ state, commit }) {
      return new Promise((resolve, reject) => {
        marketMessages({
          params: {
            ...state.marketMessagesArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_MARKET_MESSAGES, response.data.results)
            commit(mutationTypes.SET_UNSEEN_MESSAGES_NUMBER, 0)
            commit(mutationTypes.SET_ADMIN_NAV_ITEMS)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_MESSAGE]({ commit }, id) {
      return new Promise((resolve, reject) => {
        marketMessages({
          method: 'delete',
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_MESSAGE, id)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_MESSAGES]({ commit }, idList) {
      return new Promise((resolve, reject) => {
        marketMessages({
          method: 'delete',
          url: 'delete_selected/',
          data: {
            ids: idList
          },
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_MESSAGES, idList)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_MESSAGE]({ state, commit }, { id, ...payloads }) {
      return new Promise((resolve, reject) => {
        marketMessages({
          method: 'patch',
          url: `${id}/`,
          data: payloads,
        })
          .then(response => {
            const oldObj = state.marketMessages.find(obj => obj.id === id)
            if (oldObj && oldObj.archived !== response.data.archived) {
              commit(mutationTypes.DELETE_MARKET_MESSAGE, id)
            } else {
              commit(mutationTypes.UPDATE_MARKET_MESSAGE, {
                id: id,
                marketMessage: response.data,
              })
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_MESSAGES]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        marketMessages({
          method: 'patch',
          url: 'update_selected/',
          data: payloads,
        })
          .then(response => {
            commit(mutationTypes.UPDATE_MARKET_MESSAGES, payloads.ids)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_BOOKINGS_REPORT]({ state, commit }) {
      return new Promise((resolve, reject) => {
        bookingsReport({
          params: {
            ...state.bookingsReportArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_BOOKINGS_REPORT, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_CUSTOMER_CAMPAIGN_REPORT]({ state, commit }) {
      return new Promise((resolve, reject) => {
        customerCampaignsReport({
          params: {
            page: state.customerCampaignsReportArg.page,
            ...state.customerCampaignsReportArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_CUSTOMER_CAMPAIGN_REPORT, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.ADD_USER_PRODUCT_TO_CAMPAIGN]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        marketMessages({
          method: 'patch',
          url: 'update_selected/',
          data: payloads,
        })
          .then(response => {
            commit(mutationTypes.UPDATE_MARKET_MESSAGES, payloads.ids)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
})
